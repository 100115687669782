
import { Vue, Component } from 'vue-property-decorator'
import http from '@/api/http'

@Component
export default class MedicineList extends Vue {
  private list: any[] = []
  private created () {
    http.get('/papi/drug/getByIds', {ids: '795,7406,4837,796,5008,4450'}).then((res) => {
      this.list = res.result.list
    })
  }
}
